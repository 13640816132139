import React, { useState, useEffect } from 'react';
import { Card, CardHeader, Input, RadioGroup, Radio, Button, Stack, CardBody, Text, useToast } from '@chakra-ui/react';
import NavigationBar from '../Components/NavigationBar';
import { Copy } from 'lucide-react';
import * as jose from 'jose';


const Apply: React.FC = () => {
    const [formData, setFormData] = useState({
        submittedOn: new Date(),
        firstName: '',
        lastName: '',
        email: '',
        birthDate: '',
        phoneNumber: '+1',
        location: '',
        instagramHandle: '',
        linkedIn: '',
        referredBy: '',
        platform: '',
        gender: '',
        waitlistPosition: 157
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isExistingApplicant, setIsExistingApplicant] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [referralLink, setReferralLink] = useState('');
    const toast = useToast();


    useEffect(() => {
        // Parse URL parameters when component mounts
        const urlParams = new URLSearchParams(window.location.search);
        const referrerName = urlParams.get('referredBy');
        const previousPosition = urlParams.get('position');

        if (referrerName) {
            setFormData(prev => ({
                ...prev,
                referredBy: `Referred By ${referrerName}`
            }));
        }

        if (previousPosition) {
            const basePosition = parseInt(previousPosition);
            // Random increase between 1-3
            const increment = Math.floor(Math.random() * 8) + 1;
            setFormData(prev => ({
                ...prev,
                waitlistPosition: basePosition + increment
            }));
        }
    }, []);

    const validateForm = () => {
        const newErrors: { [key: string]: string } = {};

        // Check required fields
        const requiredFields = ['firstName', 'lastName', 'gender', 'email', 'phoneNumber', 'birthDate', 'location', 'instagramHandle', 'linkedIn', 'platform'];
        requiredFields.forEach(field => {
            // Check for undefined, null, empty string, or just whitespace
            const value = formData[field as keyof typeof formData];
            if (!value || (typeof value === 'string' && value.trim() === '')) {
                newErrors[field] = 'This field is required';
            }
        });

        // Validate email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (formData.email && !emailRegex.test(formData.email)) {
            newErrors.email = 'Invalid email address';
        }

        // Validate phone number (assuming North American format)
        const phoneRegex = /^\+1\d{10}$/;
        if (formData.phoneNumber && !phoneRegex.test(formData.phoneNumber)) {
            newErrors.phoneNumber = 'Invalid phone number. Please use format +1XXXXXXXXXX';
        }

        // Validate birthdate
        const birthdateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;
        if (formData.birthDate && !birthdateRegex.test(formData.birthDate)) {
            newErrors.birthDate = 'Invalid date format. Please use MM/DD/YYYY';
        } else if (formData.birthDate) {
            // Check if the date is valid (e.g., not 02/31/2000)
            const [month, day, year] = formData.birthDate.split('/').map(Number);
            const date = new Date(year, month - 1, day);
            if (date.getMonth() + 1 !== month || date.getDate() !== day || date.getFullYear() !== year) {
                newErrors.birthDate = 'Invalid date. Please enter a valid date';
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const generateReferralLink = () => {
        const baseUrl = 'https://findishq.com/apply';
        const referrerName = encodeURIComponent(`${formData.firstName} ${formData.lastName}`);
        const link = `${baseUrl}?referredBy=${referrerName}&position=${formData.waitlistPosition}`;
        setReferralLink(link);
        return link;
    };

    const handleCopyLink = () => {
        const link = generateReferralLink();
        navigator.clipboard.writeText(link);
        toast({
            title: "Link copied!",
            description: "Share this with your friends to move up the waitlist!",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    };
    

    const createJWT = async () => {
        const secretKey = process.env.REACT_APP_JWT_SECRET_KEY;

        if (!secretKey) {
            throw new Error('JWT secret key is not defined');
        }

        const encoder = new TextEncoder();
        const jwtSecret = encoder.encode(secretKey);

        const payload = {
            sub: 'WebsiteUser',
            exp: Math.floor(Date.now() / 1000) + (5 * 60), // 5 minutes expiration
        };

        return await new jose.SignJWT(payload)
            .setProtectedHeader({ alg: 'HS256' })
            .sign(jwtSecret);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target;
        if (name === 'birthDate') {
            // Remove all non-digit characters to ensure we're only dealing with numbers
            let numericValue = value.replace(/\D/g, '');

            // Limit the length to 8 characters (MMDDYYYY)
            numericValue = numericValue.substring(0, 8);

            // Format the string as 'MM/DD/YYYY'
            if (numericValue.length > 4) {
                value = `${numericValue.substring(0, 2)}/${numericValue.substring(2, 4)}/${numericValue.substring(4)}`;
            } else if (numericValue.length > 2) {
                value = `${numericValue.substring(0, 2)}/${numericValue.substring(2)}`;
            } else {
                value = numericValue;
            }
        }
        else if (name === 'phoneNumber') {
            // Remove non-numeric characters and ensure only 10 additional digits are allowed
            value = value.replace(/\D/g, '').substring(0, 11); // Allow 10 digits plus the +1
            if (!value.startsWith('1')) {
                value = '1' + value;
            }
            value = '+' + value; // Ensure +1 is always prepended
        } else {
            // Handling for other inputs
            value = e.target.value;
        }

        setFormData({ ...formData, [name]: value });
    };

    const handleRadioChange = (value: string) => {
        setFormData({ ...formData, platform: value });
    };

    const handleGenderChange = (value: string) => {
        setFormData({ ...formData, gender: value });
    };

    const getReferralMessage = () => {
        if (formData.gender === "Woman") {
            return (
                <Text fontFamily="inter">
                    2. <strong>Want to move up faster? </strong>We want men on Ishq who have been vouched for by other women (like you). So think about the BEST guy you know and once they apply using your link below, you will move to the front of our waitlist and also qualify for a <strong>free lifetime membership to Ishq.</strong>
                </Text>
            );
        }
        return (
            <Text fontFamily="inter">
                2. <strong>Want to move up faster? </strong>Refer your friends using your unique link below, and move up 50 spots for each person who applies with your referral!
            </Text>
        );
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validateForm()) {
            console.log('Form validation failed');
            return;
        }
        const cleanedPhoneNumber = formData.phoneNumber.replace(/[+\s]/g, '');

        const googleFormData = new FormData();
        googleFormData.append('entry.1777546040', formData.firstName); // Replace 'entry.xxxxxx' with the actual entry IDs from your form
        googleFormData.append('entry.1340996480', formData.lastName);
        googleFormData.append('entry.1002287530', formData.gender);
        googleFormData.append('entry.694619163', formData.email);
        googleFormData.append('entry.1470425427', cleanedPhoneNumber);
        googleFormData.append('entry.2114499952', formData.birthDate);
        googleFormData.append('entry.1512527641', formData.location);
        googleFormData.append('entry.1395833562', formData.instagramHandle);
        googleFormData.append('entry.1658277043', formData.linkedIn);
        googleFormData.append('entry.605752194', formData.referredBy);
        googleFormData.append('entry.1451027072', formData.platform);

        // ... append other form fields similarly
        console.log("formData is ", formData);
        const formAction = 'https://docs.google.com/forms/u/1/d/e/1FAIpQLSeHZDHhdRjw_95FLEVIMrzlrpnXkQXY73wxIqcAgloXTE7qyQ/formResponse'; // Replace with your form's action URL
        // const fetchOptions: RequestInit = {
        //     method: 'POST',
        //     body: googleFormData,
        // };

        try {
            const anonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
            if (!anonKey) {
                throw new Error('Supabase anon key is not defined');
            }

            const fetchOptions: RequestInit = {
                method: 'POST',
                body: googleFormData,
                headers: {
                    'Authorization': `Bearer ${anonKey}`
                }
            };
            // Replace 'YOUR_DENO_FUNCTION_ENDPOINT' with your actual Deno function's endpoint
            const response = await fetch('https://oxlgpnjntriecsthswat.supabase.co/functions/v1/submit-application', fetchOptions);
            const data = await response.json();

            if (response.status === 409) {
                // Phone number already exists
                console.log('User has already applied');
                setIsExistingApplicant(true);
                setIsSubmitted(true);
                generateReferralLink();
            } else if (!response.ok) {
                throw new Error(data.message || 'An error occurred while submitting the form.');
            } else {
                console.log('Form submitted successfully');
                setIsSubmitted(true);
                generateReferralLink();
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('An error occurred while submitting the form. Please try again.');
    
        }
    };
    return (
        <div>
            <NavigationBar />
            <div className="flex justify-center">
                <Card maxWidth={450} margin={5}>
                    {!isSubmitted ? (
                        <div>
                            <CardHeader className="flex gap-3 justify-center">
                                <h1 className="libre-baskerville-italic text-large">Apply to join</h1>
                            </CardHeader>
                            <form className="flex flex-col" onSubmit={handleSubmit}>
                                <div className="flex flex-row">
                                    <Input margin={3} isRequired name="firstName" placeholder="First Name" onChange={handleChange} />
                                    {errors.firstName && <Text fontSize="xs" color="red.500" mt={1} ml={3}>{errors.firstName}</Text>}
                                    <Input margin={3} isRequired name="lastName" placeholder="Last Name" onChange={handleChange} />
                                    {errors.lastName && <Text fontSize="xs" color="red.500" mt={1} ml={3}>{errors.lastName}</Text>}
                                </div>
                                <div className="flex flex-col">
                                    <RadioGroup onChange={handleGenderChange} name="gender">
                                        <Stack margin={3} direction="row">
                                            <Radio value="Man">Man</Radio>
                                            <Radio value="Woman">Woman</Radio>
                                            <Radio value="Nonbinary">Nonbinary</Radio>
                                        </Stack>
                                    </RadioGroup>
                                    {errors.gender && <Text fontSize="xs" color="red.500" mt={1} ml={3}>{errors.gender}</Text>}
                                </div>
                                <div className="flex flex-col">
                                    <Input width="auto" margin={3} isRequired name="email" type="email" placeholder="Email" onChange={handleChange} />
                                    {errors.email && <Text fontSize="xs" color="red.500" mt={1} ml={3}>{errors.email}</Text>}
                                    <Input width="auto" margin={3} isRequired name="phoneNumber" type="tel" onChange={handleChange} value={formData.phoneNumber} />
                                    {errors.phoneNumber && <Text fontSize="xs" color="red.500" mt={1} ml={3}>{errors.phoneNumber}</Text>}
                                    <Text fontSize="sm" color="gray.500" marginLeft={3}>
                                        We are only accepting applications from the US and Canada at this time.
                                    </Text>
                                    <Input width="auto" margin={3} isRequired name="birthDate" placeholder="Birthday (MM/DD/YYYY)" onChange={handleChange} value={formData.birthDate} />
                                    {errors.birthDate && <Text fontSize="xs" color="red.500" mt={1} ml={3}>{errors.birthDate}</Text>}
                                    <Input width="auto" margin={3} isRequired name="location" placeholder="City, State" onChange={handleChange} />
                                    {errors.location && <Text fontSize="xs" color="red.500" mt={1} ml={3}>{errors.location}</Text>}
                                    <Input 
                width="auto" 
                margin={3} 
                name="instagramHandle" 
                placeholder="Instagram Handle" 
                onChange={handleChange} 
                isRequired  // Add isRequired prop
            />
            {errors.instagramHandle && (
                <Text fontSize="xs" color="red.500" mt={1} ml={3}>
                    {errors.instagramHandle}
                </Text>
            )}
                                    <Input width="auto" margin={3} name="linkedIn" placeholder="LinkedIn" onChange={handleChange} isRequired />
                                    {errors.linkedIn && (
                <Text fontSize="xs" color="red.500" mt={1} ml={3}>
                    {errors.linkedIn}
                </Text>
            )}
                                    <Input 
                                        width="auto" 
                                        margin={3} 
                                        name="referredBy" 
                                        placeholder="Referred By" 
                                        onChange={handleChange}
                                        value={formData.referredBy}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <RadioGroup onChange={handleRadioChange} name="platform">
                                        <Stack margin={3} direction="row">
                                            <Radio value="iOS">iOS</Radio>
                                            <Radio value="Android">Android</Radio>
                                        </Stack>
                                    </RadioGroup>
                                    {errors.platform && <Text fontSize="xs" color="red.500" mt={1} ml={3}>{errors.platform}</Text>}
                                </div>
                                <div className="flex items-center justify-center">
                                    <Button fontFamily="inter" borderRadius={40} margin={3} backgroundColor="#151313" color="#f7f1f4" type="submit">
                                        Apply
                                    </Button>
                                </div>
                            </form>
                        </div>
                    ) : (
                        <div>
    <CardHeader className="flex gap-3 justify-center">
        <h1 className="libre-baskerville-italic text-large">
            {isExistingApplicant 
                ? "We've received your application!" 
                : "Thank you for applying!"}
        </h1>
    </CardHeader>
    <CardBody>
                                <Stack spacing={6}>
                                    <Stack spacing={4}>
                                        <Text fontFamily="inter">
                                            1. You are currently <strong>#{formData.waitlistPosition}</strong> on our waitlist.
                                        </Text>
                                        {getReferralMessage()}
                                        <Text fontFamily="inter">
                                            3. Make sure to check your email soon, we'll reach out to schedule a 15 minute chat to learn a bit more about you, your passions, and what you're looking for in dating.
                                        </Text>
                                    </Stack>
                                    
                                    <div className="flex items-center gap-2">
                                        <Input 
                                            value={referralLink}
                                            isReadOnly
                                            pr="4.5rem"
                                        />
                                        <Button
                                            onClick={handleCopyLink}
                                            className="flex items-center gap-2"
                                        >
                                            <Copy className="h-4 w-4" />
                                            Copy
                                        </Button>
                                    </div>
                                </Stack>
                            </CardBody>
                        </div>
                    )}
                </Card>
            </div>
        </div>
    );
};

export default Apply;
